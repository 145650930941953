import './game.css';
export default function game(){
    let height = window.innerHeight;
    return(
        <div className='game' style={{minHeight: height}}>
            <div className='c_game'>
                COMING SOON
            </div>
        </div>
    )
}