import './nft.css';
import logo_s from '../media/logo.png'
import doge from '../media/doge-icon.png'
import knight from '../media/knight.png'
import samurai from '../media/samurai.png'
import prime from '../media/prime.png'
import king from '../media/king.png'
import captain from '../media/captain.png'
import usdt from '../media/usdt.png'

import { 
    account,
    BSC_ID, 
    check_chainId, 
    web3, 
    contract_token, 
    address_mining_2, 
    contract_mining_2, 
    contract_token_2, 
    load_b_token, 
    b_D, 
    b_SPD,
    load_nft,
    N_nft1,
    N_nft2,
    N_nft3,
    N_nft4,
    N_nft5
 } from '../App';
import { notify } from './Home';
import $ from "jquery"

export default function NFT(){
    let height = window.innerHeight;
    var message = "0x616c6c20796f75722062617365206172652062656c6f6e6720746f20796f752e";
    async function M_NFT_K(){
        var signature = null;
        if(await check_chainId() != BSC_ID || account == null){
            notify("Connect Wallet first!");
        }
        else{
            try {
                signature = await window.ethereum.request({
                  method: 'personal_sign',
                  params: [message, account],
                });
            } catch (err) {
                // console.error(err);
                console.log("error")
            }
            if(signature){
                if(parseFloat(web3.utils.fromWei(await contract_token.methods.balanceOf(account).call(),'ether')) < 1){
                    notify("Insufficient funds !")
                }
                else{
                    await contract_token.methods.allowance(account,address_mining_2).call().then(async (allow_doge) =>{
                        if(allow_doge < 10*(10**18)){
                            await contract_token.methods.increaseAllowance(address_mining_2, await contract_token.methods.balanceOf(account).call()).send({from: account, maxFeePerGas: 3*10**9, gas: 90000, maxPriorityFeePerGas: 3*10**9})
                            .then(async () =>{
                                await contract_mining_2.methods.mining('10000000000000000000', signature).send({from: account, maxFeePerGas: 3*10**9, gas: 300000, maxPriorityFeePerGas: 3*10**9})
                                .then(async ()=>{
                                    notify("Transaction Successfully !", 1)
                                    await load_N_data();
                                })
                                .catch((error) =>{
                                    notify("Transaction Failed !")
                                })
                            })
                            .catch((error) =>{
                                notify("Transaction Failed !")
                            })
                        }
                        else{
                            await contract_mining_2.methods.mining('10000000000000000000', signature).send({from: account, maxFeePerGas: 3*10**9, gas: 300000, maxPriorityFeePerGas: 3*10**9})
                            .then(async ()=>{
                                notify("Transaction Successfully !", 1)
                                await load_N_data();
                            })
                            .catch((error) =>{
                                console.log(error)
                                notify("Transaction Failed !")
                            })
                        }
                    })
                    
                }
            }

        }
        
    }
    async function M_NFT_S(){
        var signature = null;
        if(await check_chainId() != BSC_ID || account == null){
            notify("Connect Wallet first!");
        }
        else{
            try {
                signature = await window.ethereum.request({
                  method: 'personal_sign',
                  params: [message, account],
                });
            } catch (err) {
                // console.error(err);
                console.log("error")
            }
            if(signature){
                if(parseFloat(web3.utils.fromWei(await contract_token.methods.balanceOf(account).call(),'ether')) < 50){
                    notify("Insufficient funds !")
                }
                else{
                    await contract_token.methods.allowance(account,address_mining_2).call().then(async (allow_doge) =>{
                        if(allow_doge < 50*(10**18)){
                            await contract_token.methods.increaseAllowance(address_mining_2, await contract_token.methods.balanceOf(account).call()).send({from: account, maxFeePerGas: 3*10**9, gas: 90000, maxPriorityFeePerGas: 3*10**9})
                            .then(async () =>{
                                await contract_mining_2.methods.mining('50000000000000000000', signature).send({from: account, maxFeePerGas: 3*10**9, gas: 400000, maxPriorityFeePerGas: 3*10**9})
                                .then(async ()=>{
                                    notify("Transaction Successfully !", 1)
                                    await load_N_data();
                                })
                                .catch((error) =>{
                                    notify("Transaction Failed !")
                                })
                            })
                            .catch((error) =>{
                                notify("Transaction Failed !")
                            })
                        }
                        else{
                            await contract_mining_2.methods.mining('50000000000000000000', signature).send({from: account, maxFeePerGas: 3*10**9, gas: 400000, maxPriorityFeePerGas: 3*10**9})
                            .then(async ()=>{
                                notify("Transaction Successfully !", 1)
                                await load_N_data();
                            })
                            .catch((error) =>{
                                console.log(error)
                                notify("Transaction Failed !")
                            })
                        }
                    })
                    
                }
            }

        }
        
    }
    async function M_NFT_P(){
        var signature = null;
        if(await check_chainId() != BSC_ID || account == null){
            notify("Connect Wallet first!");
        }
        else{
            try {
                signature = await window.ethereum.request({
                  method: 'personal_sign',
                  params: [message, account],
                });
            } catch (err) {
                // console.error(err);
                console.log("error")
            }
            if(signature){
                if(parseFloat(web3.utils.fromWei(await contract_token.methods.balanceOf(account).call(),'ether')) < 150){
                    notify("Insufficient funds !")
                }
                else{
                    await contract_token.methods.allowance(account,address_mining_2).call().then(async (allow_doge) =>{
                        if(allow_doge < 150*(10**18)){
                            await contract_token.methods.increaseAllowance(address_mining_2, await contract_token.methods.balanceOf(account).call()).send({from: account, maxFeePerGas: 3*10**9, gas: 90000, maxPriorityFeePerGas: 3*10**9})
                            .then(async () =>{
                                await contract_mining_2.methods.mining('150000000000000000000', signature).send({from: account, maxFeePerGas: 3*10**9, gas: 500000, maxPriorityFeePerGas: 3*10**9})
                                .then(async ()=>{
                                    notify("Transaction Successfully !", 1)
                                    await load_N_data();
                                })
                                .catch((error) =>{
                                    notify("Transaction Failed !")
                                })
                            })
                            .catch((error) =>{
                                notify("Transaction Failed !")
                            })
                        }
                        else{
                            await contract_mining_2.methods.mining('150000000000000000000', signature).send({from: account, maxFeePerGas: 3*10**9, gas: 500000, maxPriorityFeePerGas: 3*10**9})
                            .then(async ()=>{
                                notify("Transaction Successfully !", 1)
                                await load_N_data();
                            })
                            .catch((error) =>{
                                console.log(error)
                                notify("Transaction Failed !")
                            })
                        }
                    })
                    
                }
            }

        }
        
    }
    async function M_NFT_KG(){
        var signature = null;
        if(await check_chainId() != BSC_ID || account == null){
            notify("Connect Wallet first!");
        }
        else{
            try {
                signature = await window.ethereum.request({
                  method: 'personal_sign',
                  params: [message, account],
                });
            } catch (err) {
                // console.error(err);
                console.log("error")
            }
            if(signature){
                if(parseFloat(web3.utils.fromWei(await contract_token.methods.balanceOf(account).call(),'ether')) < 500){
                    notify("Insufficient funds !")
                }
                else{
                    await contract_token.methods.allowance(account,address_mining_2).call().then(async (allow_doge) =>{
                        if(allow_doge < 500*(10**18)){
                            await contract_token.methods.increaseAllowance(address_mining_2, await contract_token.methods.balanceOf(account).call()).send({from: account, maxFeePerGas: 3*10**9, gas: 90000, maxPriorityFeePerGas: 3*10**9})
                            .then(async () =>{
                                await contract_mining_2.methods.mining('500000000000000000000', signature).send({from: account, maxFeePerGas: 3*10**9, gas: 600000, maxPriorityFeePerGas: 3*10**9})
                                .then(async ()=>{
                                    notify("Transaction Successfully !", 1)
                                    await load_N_data();
                                })
                                .catch((error) =>{
                                    notify("Transaction Failed !")
                                })
                            })
                            .catch((error) =>{
                                notify("Transaction Failed !")
                            })
                        }
                        else{
                            await contract_mining_2.methods.mining('500000000000000000000', signature).send({from: account, maxFeePerGas: 3*10**9, gas: 600000, maxPriorityFeePerGas: 3*10**9})
                            .then(async ()=>{
                                notify("Transaction Successfully !", 1)
                                await load_N_data();
                            })
                            .catch((error) =>{
                                console.log(error)
                                notify("Transaction Failed !")
                            })
                        }
                    })
                    
                }
            }

        }
        
    }
    async function M_NFT_C(){
        var signature = null;
        if(await check_chainId() != BSC_ID || account == null){
            notify("Connect Wallet first!");
        }
        else{
            try {
                signature = await window.ethereum.request({
                  method: 'personal_sign',
                  params: [message, account],
                });
            } catch (err) {
                // console.error(err);
                console.log("error")
            }
            if(signature){
                if(parseFloat(web3.utils.fromWei(await contract_token.methods.balanceOf(account).call(),'ether')) < 1000){
                    notify("Insufficient funds !")
                }
                else{
                    await contract_token.methods.allowance(account,address_mining_2).call().then(async (allow_doge) =>{
                        if(allow_doge < 1000*(10**18)){
                            await contract_token.methods.increaseAllowance(address_mining_2, await contract_token.methods.balanceOf(account).call()).send({from: account, maxFeePerGas: 3*10**9, gas: 90000, maxPriorityFeePerGas: 3*10**9})
                            .then(async () =>{
                                await contract_mining_2.methods.mining('1000000000000000000000', signature).send({from: account, maxFeePerGas: 3*10**9, gas: 700000, maxPriorityFeePerGas: 3*10**9})
                                .then(async ()=>{
                                    notify("Transaction Successfully !", 1)
                                })
                                .catch((error) =>{
                                    notify("Transaction Failed !")
                                })
                            })
                            .catch((error) =>{
                                notify("Transaction Failed !")
                            })
                        }
                        else{
                            await contract_mining_2.methods.mining('1000000000000000000000', signature).send({from: account, maxFeePerGas: 3*10**9, gas: 700000, maxPriorityFeePerGas: 3*10**9})
                            .then(async ()=>{
                                notify("Transaction Successfully !", 1)
                                await load_N_data();
                            })
                            .catch((error) =>{
                                console.log(error)
                                notify("Transaction Failed !")
                            })
                        }
                    })
                    
                }
            }

        }
        
    }
    setInterval(async () => {
        if(await check_chainId() == BSC_ID && account != null && contract_mining_2 != null){
            if(await contract_mining_2.methods.MinToken(account).call() > 0){
                await contract_mining_2.methods.showMining(account).call().then((valueM) =>{
                    $('.showM').text(parseFloat(valueM/(10**18).toFixed(3)))
                })
            }
        }
        else{
            $('.showM').text('0.000');
        }
    }, 5000);
    var status_l = true
    async function load_M_data(){
        await load_b_token();
        $('.showSPD').text(b_SPD)
        $('.showD').text(b_D)
    }
    async function load_N_data(){
        await load_nft();
        $('.NFTK').text(N_nft1)
        $('.NFTS').text(N_nft2)
        $('.NFTP').text(N_nft3)
        $('.NFTKG').text(N_nft4)
        $('.NFTC').text(N_nft5)

    }
    setInterval(async () => {
        if(await check_chainId() == BSC_ID && account != null && contract_token_2 != null){
            if(status_l == true){
                await load_M_data();
                await load_N_data();
                status_l = false;
            }
        }
        else{
            $('.showSPD').text('0.000')
            $('.showD').text('0.000')
            $('.NFTK').text(0)
            $('.NFTS').text(0)
            $('.NFTP').text(0)
            $('.NFTKG').text(0)
            $('.NFTC').text(0)
            status_l = true
        }
    }, 2000);
    async function claimSPD(){
        if(await check_chainId() == BSC_ID && account != null && contract_mining_2 != null){
            if(await contract_mining_2.methods.MinToken(account).call() == 0){
                notify("Connect Buy NFT first!");
            }
            else{
                await contract_mining_2.methods.claim().send({from: account, maxFeePerGas: 3*10**9, gas: 90000, maxPriorityFeePerGas: 3*10**9})
                .then(async ()=>{
                    notify("Claimed Successfully !", 1)
                    await load_M_data();
                })
                .catch((error) =>{
                    notify("Claimed Failed !")
                })
            }
        }
        else{
            notify("Connect Wallet first!");
        }
    }
    async function switchD(){
        if(await check_chainId() == BSC_ID && account != null && contract_mining_2 != null){
            if(await contract_mining_2.methods.MinToken(account).call() == 0){
                notify("Connect Buy NFT first!");
            }
            else if(await contract_mining_2.methods.ClaimToken(account).call() <= 125*(10**18)){
                notify("Min SPD ~ 125");
            }
            else{
                await contract_token_2.methods.allowance(account,address_mining_2).call().then(async (allow_doge) =>{
                    if(allow_doge <= 125*(10**18)){
                        await contract_token_2.methods.increaseAllowance(address_mining_2, await contract_token_2.methods.balanceOf(account).call()).send({from: account, maxFeePerGas: 3*10**9, gas: 90000, maxPriorityFeePerGas: 3*10**9})
                        .then(async ()=>{
                            await contract_mining_2.methods.Swap().send({from: account, maxFeePerGas: 3*10**9, gas: 90000, maxPriorityFeePerGas: 3*10**9})
                            .then(async ()=>{
                                notify("Switch Successfully !", 1)
                                await load_M_data();
                            })
                            .catch((error) =>{
                                notify("Switch Failed !")
                            })
                        })
                        .catch((error) =>{
                            notify("Switch Failed !")
                        })
                    }
                    else{
                        await contract_mining_2.methods.Swap().send({from: account, maxFeePerGas: 3*10**9, gas: 90000, maxPriorityFeePerGas: 3*10**9})
                        .then(async ()=>{
                            notify("Switch Successfully !", 1)
                            await load_M_data();
                        })
                        .catch((error) =>{
                            notify("Switch Failed !")
                        })
                    }
                })
            }
        }
        else{
            notify("Connect Wallet first!");
        }
    }
    return(
        <div className='NFT' style={{minHeight: height}}>
            <div className='w_NFT'>
                <div className='dbt_db'>
                    <div className='s_NFT'>
                        <img src={knight} onClick={M_NFT_K}/>
                        <p style={{color: '#ffe400'}}>$10 <span><img src={usdt}/></span></p>
                        <p>Total: <span className='NFTK'>0</span> NFT</p>
                    </div>
                    <div className='s_NFT'>
                        <img src={samurai} onClick={M_NFT_S}/>
                        <p style={{color: '#ffe400'}}>$50 <span><img src={usdt}/></span></p>
                        <p>Total: <span className='NFTS'>0</span> NFT</p>
                    </div>
                    <div className='s_NFT'>
                        <img src={prime} onClick={M_NFT_P}/>
                        <p style={{color: '#ffe400'}}>$150 <span><img src={usdt}/></span></p>
                        <p>Total: <span className='NFTP'>0</span> NFT</p>
                    </div>
                    <div className='s_NFT'>
                        <img src={king} onClick={M_NFT_KG}/>
                        <p style={{color: '#ffe400'}}>$500 <span><img src={usdt}/></span></p>
                        <p>Total: <span className='NFTKG'>0</span> NFT</p>
                    </div>
                    <div className='s_NFT'>
                        <img src={captain} onClick={M_NFT_C}/>
                        <p style={{color: '#ffe400'}}>$1000 <span><img src={usdt}/></span></p>
                        <p>Total: <span className='NFTC'>0</span> NFT</p>
                    </div>
                </div>
                <div className='dbt_ib'>
                    <div className='i_m'>
                        <p><span className='showM'>0.000</span> <span><img src={logo_s}/></span></p>
                        <button onClick={claimSPD}>CLAIM</button>
                    </div>
                    <div className='i_m'>
                        <h3>Supper DOGE:</h3>
                        <p><span className='showSPD'>0.000</span> <span><img src={logo_s}/></span></p>
                        <button onClick={switchD}>SWITCH</button>
                    </div>
                    <div className='i_m'>
                        <h3>Doge:</h3>
                        <p><span className='showD'>0.000</span> <span><img src={doge}/></span></p>
                    </div>
                </div>
            </div>
        </div>
    )
}